import ZooxPay from "@zoox-framework/pay";
import PersonService from "./PersonService";

export default class PaymentIntentService {
  constructor() {
    const personService = new PersonService();
    const personId = personService.getPersonId();
    const accessToken = personService.getPersonToken();
    this.zooxPay = new ZooxPay({
      accessToken,
      personId,
      baseUrl: process.env.VUE_APP_ZOOXPAY_BASE_URI
    });
  }

  async getAccessToken() {
    const response = await this.zooxPay.getAccessToken();
    return response.token;
  }

  async createPaymentIntent(paymentIntent) {
    return await this.zooxPay.createPaymentIntent({
      paymentIntent: paymentIntent
    });
  }

  updatePaymentIntent(gatewayPaymentIntentId, status = "pending_capture") {
    this.zooxPay.updatePaymentIntent({ gatewayPaymentIntentId, status });
  }
}

import OrderService from "@/services/OrderService";
import PersonService from "@/services/PersonService";
import PaymentIntentService from "@/services/PaymentIntentService";

export default {
  name: "SeparatePayment",
  data() {
    return {
      personService: new PersonService(),
      paymentIntentService: null,
      personId: this.$route.query.personId || null,
      token: this.$route.params.token || null,
      accessToken: "",
      clientSecret: "",
      stripe: {},
      card: {},
      order: {},
      items: {},
      totalValue: 0,
      currency: "BRL",
      showErrorMessage: false,
      showPayment: false
    };
  },
  async created() {
    if (!this.personId || !this.token) {
      this.showErrorMessage = true;
      return;
    }

    try {
      await this.personService.tempLogin(this.personId);
      const orderService = new OrderService();
      this.order = await orderService.getOrder({ token: this.token });
      this.items = await orderService.getOrderItems({ orderId: this.order.id });
      this.currency = this.order.currency || this.currency;
      this.showPayment = true;

      this.items.forEach(item => {
        this.totalValue += item.amount * item.quantity;
      });

      this.paymentIntentService = new PaymentIntentService();
      const paymentIntent = await this.paymentIntent();
      this.clientSecret = paymentIntent.client_secret;
      this.accessToken = await this.paymentIntentService.getAccessToken();
    } catch (e) {
      this.showErrorMessage = true;
    }
  },
  methods: {
    paymentIntent() {
      const paymentIntent = {
        companyId: this.order.companyId,
        personId: this.personId,
        amount: this.totalValue,
        currency: this.currency,
        orderId: this.order.id,
        status: "pending",
        captureMethod: "manual"
      };

      return this.paymentIntentService.createPaymentIntent(paymentIntent);
    },
    payWithCard() {
      const $context = this;
      $context.loading(true);
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card
          }
        })
        .then(function(result) {
          if (result.error) {
            $context.loading(false);
            const errorMsg = document.querySelector("#card-errors");
            errorMsg.textContent = result.error.message;
          } else {
            $context.loading(false);
            $context.paymentIntentService.updatePaymentIntent(
              result.paymentIntent.id
            );
            window.location = `${$context.order.successURL}?orderId=${$context.order.id}&paymentIntentId=${result.paymentIntent.id}`;
          }
        });
    },
    cancel() {
      window.location = this.order.returnURL;
    },
    loading(isLoading) {
      // Using pure javascript because these elements are inside stripe iframe
      if (isLoading) {
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    }
  }
};
